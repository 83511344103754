import React from "react";
import Page from "../pages/page";

class View extends React.Component {
    render() {
        return (
            <div>
                <Page />
            </div>
        );
    }
}

export default View;
